import React from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     // useParams,
//   } from "react-router-dom";
//   import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

const Cuadro = () => {
    return(
        <div>
            <Container fluid = "md">
                <Row className="justify-content-md-center">
                    <h1 class="text-center"> <b>Cuadros de Mando de  Despoblación y Despoblamiento en la provincia de Ciudad Real</b></h1>
                    <h2>En Construcción</h2>
                </Row>
                <Row className="justify-content-md-center">
                    <p className="text-center"><b>El cuadro de mando no está disponible todavía</b></p>
                </Row>

            </Container>
            <Container fluid = "md">
                <Row className="justify-content-md-center">
                    <a href="https://www.dipucr.es/"><img src = "/img/dipucr.png" height="120" alt="Diputacion de Ciudad Real" /></a>
                    <a href="https://www.uclm.es/"><img src = "/img/uclm.png" height="120" alt="Universidad de Castilla - La Mancha" /></a>
                </Row>
            </Container>
      </div>
  )  
}

export default Cuadro;