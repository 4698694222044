import React from 'react'
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     // useParams,
//   } from "react-router-dom";
//   import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Accordion from 'react-bootstrap/Accordion'
import ReactGA from 'react-ga';

const Inicio = () => {

    const trackingId = "UA-181325999-1"

    ReactGA.initialize(trackingId, {debug: true,});
    ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.pageview("https://d2cr.uclm.es/");

    return(
        <div>
            <Container fluid = "md">
                <Row className="justify-content-md-justify">
                    <Accordion defaultActiveKey="0" style = {{width: '100%' }} >
                        <Card style = {{width: '100%' }} >
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    <b>Novedades</b>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <p className="text-justify">A continuación puede consultar las últimas novedades y modificaciones realizadas en esta página web:</p>
                                    <ul>
                                        <li className="text-justify"><b>[10/may/2021]</b> Usando D2CR para analizar la evolución de la población municipal en los últimos 100 años en <a href="https://www.lanzadigital.com/provincia/ciudad-real-capital-es-el-municipio-que-mas-habitantes-ha-ganado-en-el-ultimo-siglo-incrementando-su-poblacion-un-753/" target="_blank">lanzadigital.com</a></li>
                                        <li className="text-justify"><b>[10/may/2021]</b> Presentación del proyecto D2CR en la revista semanal  <a href="https://ayeryhoyrevista.com/d2cr-la-mayor-recopilacion-de-datos-sobre-la-provincia/" target="_blank">Ayer & Hoy</a></li>
                                        <li className="text-justify"><b>[8/feb/2021]</b> Publicada una noticia basada en datos socioeconómicos de D2CR en <a href="https://www.lanzadigital.com/provincia/el-campo-de-montiel-es-la-comarca-de-ciudad-real-con-menor-nivel-medio-de-rentas-por-vecino/" target="_blank">lanzadigital.com</a></li>
                                        <li className="text-justify"><b>[28/ene/2021]</b> Participación del Dr. Francisco Ruiz en el programa de TV local de Membrilla <a href="https://youtu.be/at4cFTbif08" target="_blank">"SOBRE LA MESA - EL RETO DEMOGRAFICO"</a></li>
                                    </ul>
                                </Card.Body> 
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    <b>Noticias Antiguas</b>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <ul>
                                        <li className="text-justify"><b>[26/ene/2021]</b> Miguelturra, Poblete y Pozuelo, principales destinos de las migraciones de Ciudad Real capital en los últimos 20 años. <a href="https://www.lanzadigital.com/provincia/miguelturra-poblete-y-pozuelo-fueron-los-principales-destinos-de-las-migraciones-de-ciudad-real-capital-en-los-ultimos-20-anos/" target="_blank">lanzadigital.com</a></li>
                                        <li className="text-justify"><b>[13/dic/2020]</b> El Cuadro de Mando <a href="https://d2cr.uclm.es/cm02.html" target="_blank">Demografía de la Provincia</a> incluye los nuevos criterios sobre reto demográfico en los fondos FEDER recién <a href="https://elpais.com/sociedad/2020-12-10/la-ue-prioriza-la-despoblacion-en-los-fondos-feder-con-una-definicion-de-la-que-se-beneficiara-espana.html" target="_blank">aprobados por la UE</a>.</li>
                                        <li className="text-justify"><b>[13/dic/2020]</b> Añadido el <a href="https://d2cr.uclm.es/cm05.html">cuadro de mando de socioeconomía</a></li>
                                        <li className="text-justify"><b>[23/nov/2020]</b> Añadido el <a href="https://d2cr.uclm.es/cm04.html">cuadro de mando de migraciones</a></li>
                                        <li className="text-justify"><b>[2/nov/2020]</b> Añadido el <a href="https://d2cr.uclm.es/cm03.html">cuadro de mando de territorios intermedios</a></li>
                                        <li className="text-justify"><b>[22/oct/2020]</b> <a href="https://alarcos.esi.uclm.es/noticiacompleta.php?id=180">Presentación pública</a> de la aplicación web</li>
                                    </ul>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Row>
                <br />
                <Row className="justify-content-md-justify">

                    <h1 class="text-center"> <b>D2CR: Despoblación y Despoblamiento en la provincia de Ciudad Real</b></h1>
                    <p className="text-justify">El proyecto D2CR (Despoblamiento y Despoblación en los Municipios de la Provincia de Ciudad Real) busca ayudar a combatir de forma más eficaz el problema demográfico en la provincia de Ciudad Real. Se basa en una idea clave que, el premio Nobel de Economía Joseph Stiglitz, resumió de forma clara: “No se puede mejorar lo que no se puede medir”. En esta línea, en D2CR se desarrollan y ponen a disposición de la sociedad varias herramientas para conocer adecuadamente el problema, un paso necesario para adoptar decisiones más eficaces. En los resultados incluidos en esta web se refleja el trabajo conjunto de expertos en demografía y en informática, mostrando el potencial de crear sinergias entre estas disciplinas, a priori tan distantes. D2CR sirve para enfrentar mejor el problema demográfico evitando generalizaciones, por definición incorrectas, ya que la situación es bastante diferente en unos territorios y en otros, también dentro de la provincia de Ciudad Real. También facilita los indicadores clave para analizar la situación, es decir, nos dice qué medir y cómo medirlo. </p>
                    <p className="text-justify">D2CR está financiado por la Diputación Provincial de Ciudad Real y es realizado por un equipo de profesores investigadores de la Universidad de Castilla-La Mancha, pertenecientes a los departamentos de Tecnologías y Sistemas de Información, y Geografía y Ordenación del Territorio. El proyecto es la continuación y ampliación de trabajos previos dedicados a estudiar el fenómeno de la despoblación en la provincia de Ciudad Real. Su objetivo principal es facilitar el estudio, análisis y toma de decisiones sobre el problema demográfico en la provincia de Ciudad Real. Para ello, en la web del proyecto (<a href="https://d2cr.uclm.es">https://d2cr.uclm.es</a>) se incluyen opciones que buscan facilitar a cualquier persona, sin conocimientos técnicos especiales, poder conocer en detalle la realidad poblacional de la provincia y sus municipios y zonas. Ciudadanos, medios de comunicación, investigadores del territorio y su población y situación socioeconómica, responsables de organizaciones sociales o autoridades locales y provinciales son los usuarios potenciales de los resultados del proyecto D2CR.</p>
                    <p>Las principales herramientas y contenidos que se ofrecen en la web son los siguientes:</p>
                    <ul>
                        <li className="text-justify">Un <b>informe escrito</b>, actualizado periódicamente, complementado con colecciones de datos relevantes disponibles para facilitar su uso por personas no expertas en tecnología. Está accesible en el menú Informe. Este documento es la versión más condensada de la gran cantidad de datos obtenidos y estudiados.</li>
                        <li className="text-justify">Un <b>módulo de consultas</b>, accesible en el menú Consultas, que permite buscar en el repositorio completo de datos (más de medio millón, resultado del procesamiento de casi 20 millones de registros originales), para seleccionar y extraer los datos concretos que el usuario desea, filtrando por territorios, periodos de tiempo y/o temática. Dichos datos contienen información para la provincia, sus 102 municipios y territorios intermedios (comarcas, partidos judiciales, grupos de acción local, etc). Este repositorio sigue la filosofía de ‘datos abiertos’, fundamental para hacer efectivo el principio de transparencia en el acceso de la ciudadanía a la información.</li>
                        <li className="text-justify">Una <b>colección de cuadros de mando</b> para consultar y analizar los datos más relevantes de forma interactiva y muy visual (con tablas, gráficos y mapas). Está accesible en el menú Cuadros de Mando.</li>
                    </ul>
                    {/* <p className="text-justify">Adicionalmente, en el menú ‘Datos, Territorios y Fuentes’ se explican las más de 200 variables diferentes incluidas, cada una de las cuales refiere a un tema concreto del que se tienen valores para los diversos territorios, como son la densidad de población, porcentaje de población con más de 64 años o tasa de desempleo. También se incluye la lista de territorios agrupados por tipos (municipios, comarcas, partidos judiciales, grupos de acción local, provincia, etc), y las fuentes de donde proceden los datos. </p> */}
                    <p className="text-justify">Adicionalmente, en el menú ‘Organización de la Información’ se explican las más de 200 variables diferentes incluidas, cada una de las cuales refiere a un tema concreto del que se tienen valores para los diversos territorios, como son la densidad de población, porcentaje de población con más de 64 años o tasa de desempleo. También se incluye la lista de territorios agrupados por tipos (municipios, comarcas, partidos judiciales, grupos de acción local, provincia, etc) y los periodos temporales manejados.</p>
                    <p className="text-justify">Las fuentes utilizadas para obtener los datos se presentan en el menú ‘Fuentes’. Además de la lista de fuentes y organizaciones a las que pertenecen, también se puede encontrar la lista de fuentes utilizadas para cada aspecto y/o variable.</p>

                </Row>

            </Container>
            {/* <Container fluid = "md">
                <Row className="justify-content-md-center">
                    <a href="https://www.dipucr.es/"><img src = "/img/dipucr.png" height="120" alt="Diputacion de Ciudad Real" /></a>
                    <a href="https://www.uclm.es/"><img src = "/img/uclm.png" height="120" alt="Universidad de Castilla - La Mancha" /></a>
                </Row>
            </Container> */}
      </div>
  )  
}

export default Inicio;