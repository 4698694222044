import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // useParams,
  } from "react-router-dom";
//   import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
import { 
    Navbar, 
    Nav, 
    NavDropdown
} from 'react-bootstrap'
import Inicio from './inicio.js';
import Consultas from './consultas.js';
import Cuadros from './cuadros.js'
import Informes from './informes.js';
import Contacto from './contacto.js';
import Organizacion from './organizacion.js';
import Fuentes from './fuentes.js';
import Cuadro from './cuadro.js'
import CookieConsent from "react-cookie-consent"
// import ReactGA from 'react-ga';

// const trackingId = "G-8S1T5XGFD5"

const Menu = () => {

//   ReactGA.initialize(trackingId);
//   ReactGA.pageview(window.location.pathname + window.location.search);
  return(
      <div>
          <div className="row">
              <div className="col-md-12">
                  <Router>
                      {/* <Navbar variant='dark' expand="lg" sticky="top" style = {{ backgroundColor:  '#990100' }} > */}
                      <Navbar fill="true" variant="dark" expand="xl" sticky="top" >
                          <Navbar.Brand href="https://www.dipucr.es/" target="_new">
                            <img src = "/img/dipucr.png" height="60" alt="Diputacion de Ciudad Real" />
                          </Navbar.Brand>
                          {/* <Navbar.Brand href="/"> */}
                          <Navbar.Brand href="https://alarcos.esi.uclm.es/d2cr/">
                            <img src = "/img/d2cr.png" height="60" alt="Inicio D2CR" />
                          </Navbar.Brand>
                          <Navbar.Toggle aria-controls="basic-navbar-nav" />
                          <Navbar.Collapse id="basic-navbar-nav">
                              <Nav className="mr-auto">
                                {/* <Nav.Link href="/" style = {{ fontSize: 'x-large', fontWeight: 'bold', color: 'white'}}>Inicio</Nav.Link> */}
                                {/* <Nav.Link href="/">Inicio</Nav.Link> */}
                                {/* <Nav.Link href="/informe">Informes</Nav.Link> */}
                                <Nav.Link href="https://alarcos.esi.uclm.es/d2cr/">Inicio</Nav.Link>
                                <Nav.Link href="https://alarcos.esi.uclm.es/d2cr/informes/">Informes</Nav.Link>
                                <Nav.Link href="/consultas">Consultas</Nav.Link>
                                <NavDropdown title="Cuadros de Mando" id="basic-nav-dropdown">
                                    {/* <NavDropdown.Item href="/cuadros">Información</NavDropdown.Item>
                                    <NavDropdown.Item href="/cm01.html" target="_new">Demografía de un Municipio</NavDropdown.Item>
                                    <NavDropdown.Item href="/cm02.html" target="_new">Demografía de la Provincia</NavDropdown.Item>
                                    <NavDropdown.Item href="/cm03.html" target="_new">Demografía de territorios intermedios</NavDropdown.Item>
                                    <NavDropdown.Item href="/cm04.html" target="_new">Migraciones</NavDropdown.Item>
                                    <NavDropdown.Item href="/cm05.html" target="_new">Socioeconomía</NavDropdown.Item> */}
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cuadros">Información</NavDropdown.Item>
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cm01/" target="_new">Demografía de un Municipio</NavDropdown.Item>
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cm02/" target="_new">Demografía de la Provincia</NavDropdown.Item>
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cm03/" target="_new">Demografía de territorios intermedios</NavDropdown.Item>
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cm04/" target="_new">Migraciones</NavDropdown.Item>
                                    <NavDropdown.Item href="https://alarcos.esi.uclm.es/d2cr/cm05/" target="_new">Socioeconomía</NavDropdown.Item>
                                </NavDropdown>
                                {/* <Nav.Link href="/organizacion">Organización de la información</Nav.Link> */}
                                {/* <Nav.Link href="/fuentes">Fuentes</Nav.Link> */}
                                {/* <Nav.Link href="/contacto">Contacto</Nav.Link> */}
                                <Nav.Link href="https://alarcos.esi.uclm.es/d2cr/organizacion">Organización de la información</Nav.Link>
                                <Nav.Link href="https://alarcos.esi.uclm.es/d2cr/fuentes">Fuentes</Nav.Link>
                                <Nav.Link href="https://alarcos.esi.uclm.es/d2cr/contacto">Contacto</Nav.Link>
                              </Nav>
                              {/* <Form inline>
                              <FormControl type="text" placeholder="Search" className="mr-sm-2" />
                              <Button variant="outline-success">Search</Button>
                              </Form> */}
                          </Navbar.Collapse>
                          <Navbar.Brand href="https://www.uclm.es/" target="_new">
                            <img src = "/img/uclm.png" height="60" alt="Universidad de Castilla - La Mancha" />
                          </Navbar.Brand>
                      </Navbar>
                      <br />
                      <Switch>
                          {/* <Route exact path="/">
                              <Inicio />
                          </Route>
                          <Route exact path="/informe">
                              <Informes />
                          </Route>
                          <Route path="/consultas">
                              <Consultas />
                          </Route>
                          <Route path="/cuadros">
                              <Cuadros />
                          </Route>
                          <Route path="/organizacion">
                              <Organizacion />
                          </Route>
                          <Route path="/fuentes">
                              <Fuentes />
                          </Route>
                          <Route path="/contacto">
                              <Contacto />
                          </Route>
                          <Route path="/cuadro">
                              <Cuadro />
                          </Route> */}
                          <Route exact path="/" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/'; return null;} }/> 
                          <Route path="/informe" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/informe/'; return null;} }/> 
                          <Route path="/consultas">
                              <Consultas />
                          </Route>
                          <Route path="/cuadros" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/cuadros/'; return null;} }/> 
                          <Route path="/organizacion" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/organizacion/'; return null;} }/>
                          <Route path="/fuentes" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/fuentes/'; return null;} }/>
                          <Route path="/contacto" component={() => { window.location = 'https://alarcos.esi.uclm.es/d2cr/contacto/'; return null;} }/>
                      </Switch>
                  </Router>
              </div>
          </div>
          <CookieConsent
            location="bottom"
            buttonText="Entendido"
            cookieName="d2cr_cookies"
            style={{ background: "#2B373B" }}
            buttonStyle={{ background: "#900100", color: "#ffffff"  }}
            expires={365} 
            acceptOnScroll={true}
          >
              Esta web utiliza cookies propias y de terceros para mejorar la experiencia de usuario. <a href="https://www.uclm.es/legal/informacion-legal/aviso-legal">Aviso Legal</a>
          </CookieConsent>
      </div>
  )  
}

export default Menu;