import React, { useState, useEffect } from 'react'
// import { ListGroup, ListGroupItem } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Card from 'react-bootstrap/Card'
// import Glyphicon from 'react-bootstrap/Glyphicon'
// import MultiSelect from 'react-multi-select-component'
import Select from 'react-select';
import DataTable from 'react-data-table-component';
import { ButtonGroup, Form } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import ReactGA from 'react-ga';
import jsPDF from "jspdf";
import "jspdf-autotable";


const Consultas = () => {
    const trackingId = "UA-181325999-1"

    ReactGA.initialize(trackingId, {debug: true,});
    ReactGA.pageview(window.location.pathname + window.location.search);
    // ReactGA.pageview("https://d2cr.uclm.es/consultas");


    const urlBackEnd = "https://appv3-uclmsky-d2crback.azurewebsites.net/"
    // const urlBackEnd = "https://app-uclmsky-d2crback.azurewebsites.net/"
    //  const urlBackEnd = "http://localhost:8080/"
    // const urlBackEnd = "http://127.0.0.1:5000/"

    const [aspectosJSON, setAspectosJSON] = useState([]);
    const [aspectosOptions, setAspectosOptions] = useState([]);
    const [aspectosSelected, setAspectosSelected] = useState([]);

    const [variablesJSON, setVariablesJSON] = useState([]);
    const [variablesSelected, setVariablesSelected] = useState([]);
    const [variablesOptions, setVariablesOptions] = useState([]);

    const [tiposTerritoriosJSON, setTiposTerritoriosJSON] = useState([]);
    const [tiposTerritoriosSelected, setTiposTerritoriosSelected] = useState([]);
    const [tiposTerritoriosOptions, setTiposTerritoriosOptions] = useState([]);

    const [territoriosJSON, setTerritoriosJSON] = useState([]);
    const [territoriosSelected, setTerritoriosSelected] = useState([]);
    const [territoriosOptions, setTerritoriosOptions] = useState([]);

    const [clasesPeriodosJSON, setClasesPeriodosJSON] = useState([]);
    const [clasesPeriodosSelected, setClasesPeriodosSelected] = useState([]);
    const [clasesPeriodosOptions, setClasesPeriodosOptions] = useState([]);

    const [periodosJSON, setPeriodosJSON] = useState([]);
    const [periodosSelected, setPeriodosSelected] = useState([]);
    const [periodosOptions, setPeriodosOptions] = useState([]);

    const [error, setError] = useState(null);
    // const [isLoaded, setIsLoaded] = useState(false);

    const [hechosJSON, setHechosJSON] = useState([]);
    const [cuentaHechosJSON, setCuentaHechosJSON] = useState(0);
    const [cuentaDatos, setCuentaDatos] = useState(0);
    const [actualizarHechos, setActualizarHechos] = useState(false)

    const [acotar, setAcotar] = useState(false);
    const [modalShow, setModalShow] = useState(false);

    // Columnas de la tabla que se muestra en pantalla
    //////////////////////////////////////////////////

    const columns = [
        {
          name: 'Código territorio',
          selector: 'codigo_territorio',
          sortable: true,
          grow: -1,
        },
        {
          name: 'Nombre territorio',
          selector: 'nombre_territorio',
          sortable: true,
          grow: 2,
        },
        {
          name: 'Nombre variable',
          selector: 'nombre_variable',
          sortable: true,
          grow: 2,
        },
        {
          name: 'Periodo',
          selector: 'periodo',
          sortable: true,
        },
        {
          name: 'Valor',
          selector: 'valor',
          sortable: true,
          grow: -1,
        },
        {
          name: 'Unidad',
          selector: 'unidad',
          sortable: true,
        },
        {
          name: 'Tipo territorio',
          selector: 'tipo_territorio',
          sortable: true,
        },
        {
          name: 'Aspecto',
          selector: 'aspecto',
          sortable: true,
        },
        {
          name: 'Variable',
          selector: 'variable',
          sortable: true,
        },
        {
          name: 'Relevancia Variable',
          selector: 'relevancia',
          sortable: true,
          grow: -1,
        },
        {
          name: 'Impacto Variable',
          selector: 'impacto',
          sortable: true,
          grow: -1,
        },
        {
          name: 'Años Periodo',
          selector: 'años',
          sortable: true,
          grow: -1,
        },
        {
          name: 'Clase periodo',
          selector: 'clase_periodo',
          sortable: true,
        },
      ];

    const TableStyle = {
        TableHeader: {
            style: {
                fontSize: '50px'
            },
        },
        rows: {
            style: {
                fontSize: '12px'
            },
        },
        headCells: {
            style: {
                fontSize: '18px'
            }
        },
    }
    // Funciones auxiliares
    ///////////////////////

    const handleJSON = (url, setJSON) => {
        console.log(url)
        fetch(url)
            .then(res => res.json())
            .then(
                (result) => {
                    // setIsLoaded(true);
                    setJSON(result);
                },
                (error) => {
                    // setIsLoaded(true);
                    setError("error");
                }
        )
    }

    const rellenarOptionsFromJSON = (varJSON, estructura, setOptions) => {
        const Aux = []
        varJSON.map((value, index) => {
            // Aux.push({label: value.aspecto, value: value.aspecto})
            Aux.push({label: value[estructura[0]], value: value[estructura[1]]})
            return true
        })
        setOptions(Aux)
    }

    const creaQuery = (v) => {
        // A partir de un vector de opciones seleccionadas genera una cadena para la query separada por ","
        let s = ""
        v.forEach(element => {
            s += "," + element["value"];
        });
        s = s.substring(1); // quitamos la primera ','
        return s;
    }

    const crearHechos = () => {
        const creaInterrogacion = () => {
            let query = ""
            let qaspecto = ""
            
            let qnv = ""
            let qtte = ""
            let qte = ""
            let qclase_periodo = ""
            let qperiodo = ""
            let v = []

            if (Array.isArray(variablesSelected) && variablesSelected.length > 0) {
                // qnv = "nv=" + variablesSelected[0]["value"]
                qnv = "nv=" + creaQuery(variablesSelected)
                v.push(qnv)
            }
            if (Array.isArray(aspectosSelected) && aspectosSelected.length > 0) {
                // qaspecto = "aspecto=" + aspectosSelected[0]["value"]
                if (qnv === ""){
                    qaspecto = "aspecto=" + creaQuery(aspectosSelected)
                    v.push(qaspecto)
                }
            }
            if (Array.isArray(territoriosSelected) && territoriosSelected.length > 0) {
                // qte = "id_te=" + TerritoriosSelected[0]["value"]
                qte = "id_te=" + creaQuery(territoriosSelected)
                v.push(qte)
            }
            if (Array.isArray(tiposTerritoriosSelected) && tiposTerritoriosSelected.length > 0) {
                // qtte = "tipo_te=" + tiposTerritoriosSelected[0]["value"]
                if (qte === "") {
                    qtte = "tipo_te=" + creaQuery(tiposTerritoriosSelected)
                    v.push(qtte)
                }
            }
            if (Array.isArray(periodosSelected) && periodosSelected.length > 0) {
                // qperiodo = "periodo=" + PeriodosSelected[0]["value"]
                qperiodo = "periodo=" + creaQuery(periodosSelected)
                v.push(qperiodo)
            }
            if (Array.isArray(clasesPeriodosSelected) && clasesPeriodosSelected.length > 0) {
                // qclase_periodo = "clase_per=" + ClasesPeriodosSelected[0]["value"]
                if (qperiodo === "") {
                    qclase_periodo = "clase_per=" + creaQuery(clasesPeriodosSelected)
                    v.push(qclase_periodo)
                }
            }
            query = v.join("&")
            return query
        }

        let cad = urlBackEnd + "hechos/"
        let cadcuenta = urlBackEnd + "counthechos/"
        let query = creaInterrogacion()
        if (query !=="") {
            cad += "?"+query
            cadcuenta += "?"+query
        }
        handleJSON(cad, setHechosJSON)
        handleJSON(cadcuenta, setCuentaHechosJSON)
    }

    const limpiaSeleccion = () => {
        setAspectosSelected([])
        setVariablesSelected([])
        setTiposTerritoriosSelected([])
        setTerritoriosSelected([])
        setClasesPeriodosSelected([])
        setPeriodosSelected([])

        setActualizarHechos(true)
    }

    const DownloadJSON = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = `${Object.keys(array[0]).map(value => `"${value}"`).join(",")}` + '\r\n';

        let result = array.reduce((str, next) => {
            str += `${Object.values(next).map(value => `"${value}"`).join(",")}` + '\r\n';
            return str;
        }, str);
        window.open( "data:text/csv;charset=utf-8," + escape(result))
    }

    const generatePDF = (objArray) => {
        // initialize jsPDF
        const doc = new jsPDF('lanscape');
      
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        // define the columns we want and their titles
        const tableColumn = ['Codigo Territorio', 'Nombre Territorio', 'Nombre Variable', 'Periodo', 'Valor', 'Unidad', 'Tipo Territorio', 'Aspecto', 'Variable', 'Relevancia', 'Impacto', 'Años', 'Clase Periodo'] ;
        // define an empty array of rows
        const tableRows = [];
        // console.log(tableColumn.join(";"))
        // for each row pass all its data into an array
        array.forEach(row => {
        //   console.log(row);
          const rowData = [
            row.codigo_territorio,
            row.nombre_territorio,
            row.nombre_variable,
            row.periodo,
            row.valor,
            row.unidad,
            row.tipo_territorio,
            row.aspecto,
            row.variable,
            row.relevancia,
            row.impacto,
            row.años,
            row.clase_periodo
          ];
          // push each rowData
          tableRows.push(rowData);
        //   console.log(rowData.join(";"));
        });
      
      
        // startY is basically margin-top
        doc.text("D2CR - https://d2cr.uclm.es/", 14, 15);
        doc.autoTable(tableColumn, tableRows, { startY: 20 });
        // const date = Date().split(" ");
        // we use a date string to generate our filename.
        // const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
        // ticket title. and margin-top + margin-left
        // doc.text("Closed tickets within the last one month.", 14, 15);
        // we define the name of our PDF file.
        doc.save(`datosD2CR.pdf`);
      };
      

    function MyVerticallyCenteredModal(props) {
        return (
            <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Ayuda acerca de las consultas D2CR
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    Para poder realizar una consulta lo primero es realizar un filtrado en la parte izquierda de la pantalla, donde se muestran tres filtros:
                    <ul>
                        <li><b>Temático:</b> Permite filtrar por los distintos aspectos de los datos (edad, sexo, renta, ...) y elegir todas o algunas de las variables asociadas a los aspectos seleccionados.</li>
                        <li><b>Territorial:</b> Permite filtrar por los distintos tipos de territorios y elegir todos o algunos de los territorios de los tipos seleccionados.</li>
                        <li><b>Temporal:</b> Permite filtrar por clases de periodos temporales (5 años, 20 años, censo, padrón, ...) y elegir todos o algunos de los periodos concretos de las clases seleccionadas.</li>
                    </ul>
                </p>
                <br/>
                <p>
                    En una consulta puede utilizarse cualquier combinación de los tres filtros anteriores. Los datos resultantes serán aquellos que cumplen a la vez los filtros activados. Si no se especifica un filtro en alguna de las dimensiones, esa dimensión lo limitará los resultados. Por ejemplo, el filtro temporal vacío significa que se obtendrán datos de todos los periodos temporales disponibles.
                </p>
                <p>
                    Tras realizar la selección de los filtros deseados se debe pulsar en el botón <b>Actualizar datos</b> para que los datos mostrados reflejen la aplicación de los filtros. Es interesante ver cuántos registros de resultados se han obtenido.
                </p>
                <p>
                    Por último, si los datos son de interés, se pueden descargar en formato abierto CSV (que se puede abrir después con programas como Excel) o en PDF. Una descarga no permite más de 20 mil registros a la vez.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
            </Modal>
        );
    }


    // Carga de datos inicial
    /////////////////////////

    useEffect(() => {
        handleJSON(urlBackEnd + "aspectos/", setAspectosJSON)
        // handleJSON(urlBackEnd + "variables/", setVariablesJSON)
        handleJSON(urlBackEnd + "tiposterritorios/", setTiposTerritoriosJSON)
        // handleJSON(urlBackEnd + "territorios/", setTerritoriosJSON)
        handleJSON(urlBackEnd + "clasesperiodos/", setClasesPeriodosJSON)
        // handleJSON(urlBackEnd + "periodos/", setPeriodosJSON)
        handleJSON(urlBackEnd + "hechos/", setHechosJSON)
        handleJSON(urlBackEnd + "counthechos/", setCuentaHechosJSON)
    },[])


    // Rellenar las opciones de los dropdown cuando cambian los JSON
    ////////////////////////////////////////////////////////////////
    useEffect(() => {
        rellenarOptionsFromJSON(aspectosJSON, ['aspecto', 'aspecto'], setAspectosOptions)
    }, [aspectosJSON])

    useEffect(() => {
        rellenarOptionsFromJSON(variablesJSON, ['nombre', 'nv'], setVariablesOptions)
    }, [variablesJSON])

    useEffect(() => {
        rellenarOptionsFromJSON(tiposTerritoriosJSON, ['tipo_territorio', 'tipo'], setTiposTerritoriosOptions)
    }, [tiposTerritoriosJSON])

    useEffect(() => {
        rellenarOptionsFromJSON(territoriosJSON, ['nombre', 'id_te'], setTerritoriosOptions)
    }, [territoriosJSON])

    useEffect(() => {
        rellenarOptionsFromJSON(clasesPeriodosJSON, ['clase', 'clase'], setClasesPeriodosOptions)
    }, [clasesPeriodosJSON])

    useEffect(() => {
        rellenarOptionsFromJSON(periodosJSON, ['periodo', 'periodo'], setPeriodosOptions)
    }, [periodosJSON])

    // Efectos de unos dropdowns a otros
    ////////////////////////////////////

    useEffect(() => {
        let cad = ""
        if (!Array.isArray(aspectosSelected)|| !aspectosSelected.length) {
            cad = urlBackEnd + 'variables/'
        } else { // if (aspectosSelected.length >0)
            // cad = urlBackEnd + 'variables/' + aspectosSelected[0]["value"]
            let q = creaQuery(aspectosSelected)
            cad = urlBackEnd + 'variables/' + q
        }
        handleJSON(cad, setVariablesJSON)
        setVariablesSelected([])
        
        // setActualizarHechos(true)
    }, [aspectosSelected]);

    useEffect(() => {
        let cad = ""
        if (!Array.isArray(tiposTerritoriosSelected)|| !tiposTerritoriosSelected.length) {
            cad = urlBackEnd + 'territorios/'
        } else { // if (tiposTerritoriosSelected.length >0)
            // cad = urlBackEnd + 'territorios/' + tiposTerritoriosSelected[0]["value"]
            let q = creaQuery(tiposTerritoriosSelected)
            cad = urlBackEnd + 'territorios/' + q
        }
        handleJSON(cad, setTerritoriosJSON)
        setTerritoriosSelected([])

        // setActualizarHechos(true)
    }, [tiposTerritoriosSelected]);

    useEffect(() => {
        let cad = ""
        if (!Array.isArray(clasesPeriodosSelected)|| !clasesPeriodosSelected.length) {
            cad = urlBackEnd + 'periodos/'
        } else { // if (tiposTerritoriosSelected.length >0)
            // cad = urlBackEnd + 'periodos/' + ClasesPeriodosSelected[0]["value"]
            let q = creaQuery(clasesPeriodosSelected)
            cad = urlBackEnd + 'periodos/' + q
        }
        handleJSON(cad, setPeriodosJSON)
        setPeriodosSelected([])

        // setActualizarHechos(true)
    }, [clasesPeriodosSelected]);


    // Rellenar la tabla de Hechos cuando cambian los elementos seleccionados en los dropdowns
    //////////////////////////////////////////////////////////////////////////////////////////
    // useEffect(() => {
    //     setActualizarHechos(true)
    // },[aspectosSelected, variablesSelected, tiposTerritoriosSelected, territoriosSelected, clasesPeriodosSelected, periodosSelected])

    // useEffect(() => {
    //     setActualizarHechos(true)
    // },[variablesSelected])

    // useEffect(() => {
    //     setActualizarHechos(true)
    // },[territoriosSelected])

    // useEffect(() => {
    //     setActualizarHechos(true)
    // },[periodosSelected])

    // useEffect(() => {
    //     setCuentaHechosJSON(hechosJSON.length)
    // },[hechosJSON])

    useEffect(() => {


        if (actualizarHechos) {
            setActualizarHechos(false)
            crearHechos()
        }
    },[actualizarHechos])

    useEffect(() => {
        if (cuentaHechosJSON.length>0){
            setCuentaDatos(cuentaHechosJSON[0]["cuenta"])
        }
        else{
            setCuentaDatos(0)
        }
    }, [cuentaHechosJSON])
    
    const mostrarAcotar = () => {
        setAcotar(!acotar)
    }

    return (
        <div>
            <Container fluid  >
                <Row className="justify-content-md-center">
                    {(error) &&
                        <Alert variant="danger" dismissible onClose={() => setError(null)}>
                            <Alert.Heading>Se ha producido el error: {error}. Por favor, intentelo dentro unos minutos.</Alert.Heading>
                            <p>Si el problema persiste, por favor, notifiquelo a <a href="mailto:proyecto.d2cr@uclm.es">proyecto.d2cr@uclm.es</a></p>
                        </Alert>
                    }
                </Row>

                <Row className="justify-content-md-center" >

                    <Col sm="2">
                        <Row className="justify-content-md-center" >
                            <ButtonGroup className="mr-2" aria-label="Grupo limpiar">
                            <Button
                                variant="danger"
                                onClick={limpiaSeleccion}
                            >
                                Limpiar filtros .
                                <i class="fas fa-times" /> 
                            </Button>
                            </ButtonGroup>
                            <ButtonGroup className="mr-2" aria-label="Grupo actualizar">
                            <Button
                                variant="danger"
                                onClick={() => setActualizarHechos(true)}
                            >
                                Actualizar Datos . 
                                <i class="fas fa-recycle" /> 
                            </Button>
                            </ButtonGroup>
                        </Row>

                        <br />
                        <Card border="danger">
                            <Card.Header><b>Filtro Temático</b></Card.Header>
                            <Card.Body>
                                <p><b>Aspectos</b></p>
                                <Select
                                    placeholder="Todos los Aspectos..."
                                    isMulti
                                    options={aspectosOptions}
                                    onChange={setAspectosSelected}
                                    value={aspectosSelected}
                                />
                                <br />
                                <p><b>Variables</b></p>
                                <Select
                                    placeholder="Todas las Variables..."
                                    isMulti
                                    options={variablesOptions}
                                    onChange={setVariablesSelected}
                                    value={variablesSelected}
                                />
                            </Card.Body>
                        </Card>
                        <br />
                        <Card border="danger">
                            <Card.Header><b>Filtro Territorial</b></Card.Header>
                            <Card.Body>
                                {/* <Form.Switch
                                    enabled
                                    id = "acotar_ter"
                                    label = "Acotar Ámbito Territorial"
                                    // checked = {acotar}
                                    // onChange = {mostrarAcotar()}
                                    // onClick = {setAcotar(!acotar)}
                                /> */}
                                {/* {acotar &&  */}
                                {/* <>
                                <p><b>Tipos de Territorio Agregado</b></p>
                                <Select
                                    disabled
                                    // enabled={acotar_ter}
                                    placeholder="Todos los Tipos..."
                                    options={tiposTerritoriosOptions}
                                    onChange={setTiposTerritoriosSelected}
                                    value={tiposTerritoriosSelected}
                                />
                                </> */}
                                {/* } */}
                                <p><b>Tipos de Territorio</b></p>
                                <Select
                                    placeholder="Todos los Tipos..."
                                    isMulti
                                    options={tiposTerritoriosOptions}
                                    onChange={setTiposTerritoriosSelected}
                                    value={tiposTerritoriosSelected}
                                />
                                <br />
                                <p><b>Territorios</b></p>
                                <Select
                                    placeholder="Todos los Territorios..."
                                    isMulti
                                    options={territoriosOptions}
                                    onChange={setTerritoriosSelected}
                                    value={territoriosSelected}
                                />
                            </Card.Body>
                        </Card>
                        <br />
                        <Card border="danger">
                            <Card.Header><b>Filtro Temporal</b></Card.Header>
                            <Card.Body>
                                <p><b>Clases de periodos</b></p>
                                <Select
                                    placeholder="Todas las Clases..."
                                    isMulti
                                    options={clasesPeriodosOptions}
                                    onChange={setClasesPeriodosSelected}
                                    value={clasesPeriodosSelected}
                                />
                                <br />
                                <p><b>Periodos</b></p>
                                <Select
                                    placeholder="Todos los Periodos..."
                                    isMulti
                                    options={periodosOptions}
                                    onChange={setPeriodosSelected}
                                    value={periodosSelected}
                                />
                            </Card.Body>
                        </Card>
                        <br />
                    </Col>

                    <Col sm="10">
                        <Row className="justify-content-md-center">
                            <h1 className="text-center"> <b>Total Datos Seleccionados: </b>{cuentaDatos.toLocaleString('es-ES')} </h1> 
                        </Row>
                        <Row className="justify-content-md-center" >
                            <ButtonGroup className="mr-2" aria-label="Grupo download CSV">
                                <Button
                                    variant="outline-danger"
                                    onClick={() => DownloadJSON(hechosJSON)}
                                >
                                    Descargar Selección en CSV .
                                    <i class="fas fa-download" /> 
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup className="mr-2" aria-label="Grupo download PDF">
                                <Button
                                    variant="outline-danger"
                                    onClick={() => generatePDF(hechosJSON)}
                                >
                                    Descargar Selección en PDF .
                                    <i class="fas fa-download" /> 
                                </Button>
                            </ButtonGroup>
                            <ButtonGroup className="mr-2" aria-label="Grupo ayuda">
                                <Button
                                    variant="danger"
                                    onClick={() => setModalShow(true)}
                                >
                                    Ayuda .
                                    <i class="fas fa-question" /> 
                                </Button>
                            </ButtonGroup>
                        </Row>
                        {/* <p class="text-center"><b>Total registros: </b>{(cuentaHechosJSON.length>0) && cuentaHechosJSON[0]["cuenta"]} </p> */}
                        {/* <p class="text-center"><b>Total registros: </b>{cuentaHechosJSON} </p> */}
                        {/* <p className="text-center"><b>Total registros: </b>{cuentaDatos} </p> */}
                        <DataTable
                            // title="Hechos"
                            columns={columns}
                            data={hechosJSON}
                            pagination={true}
                            striped={true}
                            dense={true}
                            paginationPerPage={20}
                            noDataComponent = {"No hay datos para los filtros seleccionados"}
                            customStyles = {TableStyle}
                        />
                    </Col>
                    </Row>
                    <MyVerticallyCenteredModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
              </Container>
        </div>
  )
}

export default Consultas;
