import React from 'react';
// import logo from './logo.svg';
import './App.css';

// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     // useParams,
//   } from "react-router-dom";
// import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'
import Menu from './menu.js'
import ReactGA from 'react-ga';

function App() {
  // const trackingId = "G-8S1T5XGFD5"
  const trackingId = "UA-181325999-1"

  ReactGA.initialize(trackingId, {debug: true,});
  ReactGA.pageview(window.location.pathname + window.location.search);
  // ReactGA.pageview("https://d2cr.uclm.es/");

  return (
    <div className="App">
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <Menu />
    </div>
  );
}

export default App;
